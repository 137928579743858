<template>
    <div>
        <el-row class="mylogin">
            <el-col :xs="24" :sm="24" :md="8" :lg="8" :xl="8" class="login">
                <div class="login_logo"><img src="/img/LogoDark.png" alt=""></div>
                <el-card>
                    <h2 class="auth_h2">{{$t('message.login')}}</h2>
                    <el-form class="login-form radus" :model="form" :rules="rules" ref="form" @submit.native.prevent="login">
                        <el-form-item prop="phone">
                            <el-input v-model="form.phone" placeholder="Phone" prefix-icon="el-icon-user"></el-input>
                        </el-form-item>
                        <el-form-item prop="password">
                            <el-input v-model="form.password" placeholder="Password" type="password" prefix-icon="el-icon-lock">
                            </el-input>
                        </el-form-item>
                        <el-form-item>
                            <el-button :loading="loading" :disabled="loading ? true : false" class="login-button" type="primary" native-type="submit" block>{{$t('message.sign_in')}} </el-button>
                        </el-form-item>
                        <!-- <el-button type="text" @click="dialogVisible = true">Забыли пароль ?</el-button> -->
                    </el-form>
                </el-card>
            </el-col>
            <el-col :xs="24" :sm="24" :md="16" :lg="16" :xl="16">
                <div class="loginfon"><img src="img/fon3.jpg" alt=""></div>
            </el-col>
        </el-row>
          <!-- MODAL DIALOG -->
        <el-dialog
          title="Creating Password"
          :visible.sync="dialogVisible"
          width="23%">
          <div v-loading="loading_validate">
          <!-- Send phone number -->
            <div v-if="valid.phone">
              <el-form class="login-form radus">
                <el-form-item>
                  <el-input v-model="model.valid_phone" placeholder="Phone" prefix-icon="el-icon-user"></el-input>
                </el-form-item>
              </el-form>
              <span slot="footer" class="dialog-footer">
                <el-button @click="dialogVisible = false">Cancel</el-button>
                <el-button type="primary" @click="send_phone">Confirm</el-button>
              </span>
            </div>
            <!-- Validate Code -->
            <div v-if="valid.phone==false&&valid.confirm_code==false">
              <el-form class="login-form radus">
                <el-form-item>
                  <el-input v-model="model.confirm_code" placeholder="Confirm code"></el-input>
                </el-form-item>
              </el-form>
              <span slot="footer" class="dialog-footer">
                <el-button type="primary" @click="confirmCode">Send code</el-button>
              </span>
            </div>
          <!-- send password -->
            <div v-if="valid.confirm_code&&valid.password==false">
              <el-form class="login-form radus">
                <el-form-item>
                  <el-input v-model="model.password" placeholder="Password" type="password" prefix-icon="el-icon-lock"></el-input>
                </el-form-item>
                <el-form-item>
                  <el-input v-model="model.confirm_password" placeholder="Confirm Password" type="password" prefix-icon="el-icon-lock"></el-input>
                </el-form-item>
              </el-form>
              <span slot="footer" class="dialog-footer">
                <el-button @click="resetPassword" type="primary" >Confirm</el-button>
              </span>
            </div>
        <!-- // send password -->
          </div>
        </el-dialog>
    </div>

</template>

<script>
import {mapActions,mapGetters} from 'vuex'
import { log } from 'util';

export default {
  name: "login",
  data() {
    return {
      user:[],
      phone:'',
      form: {
        password: '',
        phone: ''
      },
      validCredentials: {
        username: "lightscope",
        password: "lightscope"
      },
      valid:{
        phone : true,
        confirm_code:false,
        password:false
      },
      model: {
        valid_phone: "",
        confirm_code: null,
        password:'',
        confirm_password:''
      },
      loading: false,
      loading_validate : false,
      dialogVisible:false,
      rules: {
        phone: [
          {
            required: true,
            message: "Пожалуйста, введите телефон",
            trigger: "blur"
          },
          { min: 13, max: 13, message: 'Длина должна быть 13', trigger: 'blur' }
        ],
        password: [
          { required: true, message: "Пожалуйста, введите телефон", trigger: "blur" },
          {
            min: 5,
            message: "Длина должна быть 5",
            trigger: "blur"
          }
        ]
      }
    };
  },
  watch: {
    $route: {
      handler: function(route) {
        const query = route.query;
        if (query) {
          this.redirect = query.redirect;
          this.otherQuery = this.getOtherQuery(query);
        }
      },
      immediate: true
    }
  },
  methods: {
    ...mapActions({
        postLogin : 'auth/login',
        passwordReset: 'users/resetPassword',
        code: 'users/code',
        restore: 'users/restore',
    }),
    getOtherQuery(query) {
      return Object.keys(query).reduce((acc, cur) => {
        if (cur !== "redirect") {
          acc[cur] = query[cur];
        }
        return acc;
      }, {});
    },
    login() {
      this.$refs['form'].validate((valid) => {
          if (valid){
            this.loading = true
            this.postLogin(this.form)
              .then(res=> {
                  this.loading = false
                  this.$router.push({
                      path: this.redirect || "/",
                      query: this.otherQuery
                  });
              })
              .catch(err => {
                 this.loading = false
                 this.Message_Error(err.response.data.code,err.response.data.message);
              });
          }
      });
    },
    send_phone() {
      if (this.model.valid_phone.length != 13) {
        this.Message_Error('','Номер телефона введен неверно')
        return;
      }
      this.loading_validate = true
      this.restore({phone:this.model.valid_phone})
        .then(res=> {
          this.valid.phone = false
          this.Message_Success('This code: '+res.data.validate_code);
          this.loading_validate = false
        })
        .catch(err => {
          this.loading_validate = false
          this.Message_Error(err.code,err.error.message);
        });
    },
    confirmCode(){
      if (this.model.confirm_code == null) {
        this.Message_Error('','Код не введен')
        return;
      }
      this.loading_validate = true
      this.code({code:this.model.confirm_code})
        .then(res=> {
          this.user = res.data.user
          this.valid.confirm_code = false
          this.valid.confirm_code = true
          this.loading_validate = false
        })
        .catch(err => {
          this.loading_validate = false
          this.Message_Error('',err.error.message);
        });
    },
    resetPassword(){
      if(this.model.password != this.model.confirm_password && this.model.password != '' && this.model.confirm_password !=''){
        this.Message_Error('','Код не совпадает, пожалуйста, проверьте cеще раз');
        return false;
      }
      this.loading_validate = true
      this.passwordReset({id:this.user.id,password:this.model.password})
        .then(res=> {
          this.valid.phone = true,
          this.valid.confirm_code = false,
          this.valid.password = false
          this.loading_validate = false
          this.dialogVisible = false
          this.Message_Success('Ваш пароль изменился, пожалуйста войдите');           
        })
        .catch(err => {         
          console.log(err);
          this.loading_validate = false
        });
    },
    Message_Success(message){
      this.$notify.success({
          dangerouslyUseHTMLString: true,
          title: 'Success',
          message: '<strong>'+message+'</strong>',
          duration: 6000
        });
    },
    Message_Error(code,message){
      this.$notify.error({
          dangerouslyUseHTMLString: true,
          title: 'Error ' + code,
          message: '<strong>'+message+'</strong>',
          position: 'bottom-right'
        });
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.login {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    flex-direction: column;
}

.login_logo{
    width: 300px;
    display: block;
    margin-bottom: 30px;
}
.login_logo img{
    width: 100%;
}

.login-form {
  width: 290px;
}
.forgot-password {
  margin-top: 10px;
  color: #224b69;
}
</style>
<style lang="scss">
$teal: rgb(0, 124, 137);
.el-button--primary {
  background: $teal;
  border-color: $teal;

  &:hover,
  &.active,
  &:focus {
    background: lighten($teal, 7);
    border-color: lighten($teal, 7);
  }
}
.login .el-input__inner:hover {
  border-color: $teal;
}
.login .el-input input {
  padding-left: 35px;
}
.login .el-card {
  padding-top: 0;
  padding-bottom: 30px;
}
.auth_h2 {
  padding-bottom: 20px;
}
a {
  color: $teal;
  text-decoration: none;
  &:hover,
  &:active,
  &:focus {
    color: lighten($teal, 7);
  }
}
.login .el-card {
//   width: 340px;
  display: flex;
  justify-content: center;
    padding: 30px;
    border-radius: 15px;
}
.mylogin,
.loginfon{
    width: 100%;
    height: 100vh;
}
.loginfon img{
    width: 100%;
    height: 100vh;
    -o-object-fit: cover;
    object-fit: cover;
    object-position: left;
   
}
.loginfon{
     position: relative;
    z-index: 1;
}
.loginfon::before{
    content: '';
    width: 100%;
    height: 100vh;
    display: block;
    position: absolute;
    top: 0px;
    left: 0px;
    z-index: 2;
    background-color: #224b693b;
}
.radus .el-form-item .el-form-item__content .el-input .el-input__inner{
       border-radius: 25px !important;
}
.login-button{
    width: 100%;
    background-color: #224b69;
    padding: 12px !important;
    border-radius: 25px;
}
.login-button:hover,
.login-button:active,
.login-button:focus{
    background-color: #224b69;
}
</style>
